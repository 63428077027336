import { AccountApi, EnumMigrationStatus } from '@blank/api'
import { EnumBankTrackingEvents } from 'modules/bank/lib/tracking/events'
import { useOrderNewTokamakCardMigrationModal } from 'modules/bank/modals/card/OrderNewTokamakCardMigrationModal/useOrderNewTokamakCardMigrationModal'
import { trackEvent } from 'modules/common/lib/amplitude/trackEvent'
import { useEffect } from 'react'

interface Props {
  internalAccount: AccountApi | undefined
}

export const useDisplayTokamakMigrationBlockingModalOnMount = ({
  internalAccount,
}: Props) => {
  const isAccountToBeMigrated =
    internalAccount?.migrationStatus === EnumMigrationStatus.TO_BE_MIGRATED

  const { showModal: showOrderNewTokamakCardMigrationModal } =
    useOrderNewTokamakCardMigrationModal({
      limitActivationDate: internalAccount?.limitActivationDate,
    })

  useEffect(() => {
    if (isAccountToBeMigrated) {
      showOrderNewTokamakCardMigrationModal()
      trackEvent({
        eventType:
          EnumBankTrackingEvents.TOKAMAK_MIGRATION_CARD_ORDER_MODAL_VIEWED,
      })
    }
  }, [isAccountToBeMigrated, showOrderNewTokamakCardMigrationModal])
}
