export enum EnumBankTrackingEvents {
  // Debit transaction enrichment events
  UPDATE_DEBIT_TRANSACTION_CATEGORY = 'DEBIT_ENRICHMENT_CATEGORY',
  UPDATE_DEBIT_TRANSACTION_NOTE = 'DEBIT_ENRICHMENT_NOTE',
  UPDATE_DEBIT_TRANSACTION_PROFESSIONAL = 'DEBIT_ENRICHMENT_PROFESSIONAL',
  UPDATE_DEBIT_TRANSACTION_VAT = 'DEBIT_ENRICHMENT_VAT',

  // Credit transaction enrichment events
  UPDATE_CREDIT_TRANSACTION_TURNOVER = 'CREDIT_TRANSACTION_TURNOVER',
  UPDATE_CREDIT_TRANSACTION_NOTE = 'CREDIT_TRANSACTION_NOTE',
  UPDATE_CREDIT_TRANSACTION_TURNOVER_TYPE = 'CREDIT_TRANSACTION_TURNOVER_TYPE',
  UPDATE_CREDIT_TRANSACTION_VAT = 'CREDIT_TRANSACTION_VAT',

  // Debit transaction attachment events
  POST_DEBIT_TRANSACTION_ATTACHMENT = 'POST_DEBIT_TRANSACTION_ATTACHMENT',
  VALIDATE_DEBIT_TRANSACTION_ATTACHMENT = 'VALIDATE_DEBIT_TRANSACTION_ATTACHMENT',
  DELETE_DEBIT_TRANSACTION_ATTACHMENT = 'DELETE_DEBIT_TRANSACTION_ATTACHMENT',

  // Credit transaction attachment events
  POST_CREDIT_TRANSACTION_ATTACHMENT = 'POST_CREDIT_TRANSACTION_ATTACHMENT',
  VALIDATE_CREDIT_TRANSACTION_ATTACHMENT = 'VALIDATE_CREDIT_TRANSACTION_ATTACHMENT',
  DELETE_CREDIT_TRANSACTION_ATTACHMENT = 'DELETE_CREDIT_TRANSACTION_ATTACHMENT',

  // Beneficiary
  ADD_BENEFICIARY = 'Add a new beneficiary',
  VALIDATE_NEW_BENEFICIARY = '[Beneficiary] Validate New Beneficiary',
  MODAL_ADD_BENEFICIARY_SUCCESS = 'Modal add a new beneficiary success',
  MODAL_UPDATE_BENEFICIARY_NAME = 'Modal change beneficiary name',
  MODAL_DELETE_BENEFICIARY = 'Modal delete beneficiary',
  MODAL_DELETE_BENEFICIARY_ERROR = '[Transfer] Error Delete Beneficary',
  MODAL_DELETE_BENEFICIARY_SUCCESS = '[Transfer] Validate Delete Beneficary',
  MODAL_UPDATE_BENEFICIARY_SUCCESS = '[Transfer] Validate Change Beneficary Name',
  MODAL_UPDATE_BENEFICIARY_ERROR = '[Transfer] Error Change Beneficary Name',

  // Transfer
  CREATE_TRANSFER_SUMMARY = 'Create a new transfer summary',
  MODAL_REQUEST_TRANSFER = 'Modal request transfer',
  MODAL_TRANSFER_SUCCESS = 'Modal transfer Success',
  MODAL_TRANSFER_ERROR = 'Modal transfer error',

  TRANSACTION_INFORMATION = 'Information Transaction',

  // RIB
  MODAL_PREVIEW_RIB = 'Modal preview RIB',
  DOWNLOAD_RIB = 'Download RIB',

  // Transaction export
  SIMPLE_TRANSACTIONS_EXPORT = 'SIMPLE_TRANSACTIONS_EXPORT',
  ADVANCED_TRANSACTIONS_EXPORT = 'ADVANCED_TRANSACTIONS_EXPORT',

  // Link invoicing document to transaction
  INIT_LINK_BLANK_DOCUMENT_TO_TRANSACTION_FLOW = 'INIT_LINK_BLANK_DOCUMENT_TO_TRANSACTION_FLOW',
  LINK_BLANK_DOCUMENT_TO_TRANSACTION = 'LINK_BLANK_DOCUMENT_TO_TRANSACTION',

  // Transaction certificate
  CERTIFICATE_TRANSFER_SUCCESS_MODAL = 'Certificate Transfer Success Modal',
  CERTIFICATE_TRANSFER_DETAILS = 'Certificate Transfer Details',

  // Enterprise Mandates
  ENTERPRISE_MANDATE_FORM_STARTED = '[Mandate] B2B Mandate Form Started',
  ENTERPRISE_MANDATE_FORM_SUCCESS = '[Mandate] B2B Mandate Form Success',

  // Card
  TOKAMAK_MIGRATION_CARD_ORDER_MODAL_VIEWED = '[Card] Tokamak Migration Card Order Modal Viewed',
}
