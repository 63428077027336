import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useContentModal } from 'modules/common/modals/useContentModal/useContentModal'
import { OrderNewTokamakCardMigrationModalContent } from './OrderNewTokamakCardMigrationModalContent/OrderNewTokamakCardMigrationModalContent'

interface Props {
  limitActivationDate: string | undefined
}

export const useOrderNewTokamakCardMigrationModal = ({
  limitActivationDate,
}: Props) => {
  const { t, i18n } = useTranslation('bank-components')

  return useContentModal({
    headerTitle: t('orderNewTokamakCardMigrationModal.headerTitle'),
    content: (
      <OrderNewTokamakCardMigrationModalContent
        limitActivationDate={limitActivationDate}
      />
    ),
    width: 'large',
    i18n,
    shouldCloseOnOverlayClick: false,
    shouldCloseOnEsc: false,
    displayCloseHeaderButton: false,
  })
}
