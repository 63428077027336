import { ExternalLinks } from '../type'

const faqDomainUrl = 'https://aide.essentielpro.lcl.fr'

export const LCL_EXTERNAL_LINKS_FR: ExternalLinks = {
  cguLink:
    'https://assets.website-files.com/62baffcf7b968d00045514af/633c2d84f343514cadd7c3d0_LCL%20-%20CGU_Plateforme%20de%20gestion%20Okali%20x%20Client%20final_Vdef%20(1).pdf',
  bankingOrganizationPrivacyPolicyLink:
    'https://assets.website-files.com/62baffcf7b968d00045514af/633c5a32c093a77bbf7d89e3_ca_Politique%20de%20protection%20des%20donnees%20Okali%20x%20Essentiel%20Pro_VF-n.pdf',
  brandPrivacyPolicyLink: 'https://www.lcl.fr/politique-protection-des-donnees',
  appStoreLink: 'https://apps.apple.com/us/app/essentiel-pro-lcl/id1618398607',
  googlePlayLink: 'https://play.google.com/store/apps/details?id=com.lcl.pro',
  faq: faqDomainUrl,
  mandateSepa: `${faqDomainUrl}/hc/fr/articles/5605100609821`,
  invoicingFaq: `${faqDomainUrl}/hc/fr/sections/5289100886941`,
  requestHelp: `${faqDomainUrl}/hc/fr/requests/new`,
  rcProInfo: '',
  decadeGuaranteeInfo: '',
  multiriskInfo: '',
  legalPlaceCompany: '',
  helpNonDiffusible: `${faqDomainUrl}/hc/fr/articles/5517198573597`,
  helpNonEligible: `${faqDomainUrl}/hc/fr/articles/5517826655773`,
  helpBeneficialOwners: `${faqDomainUrl}/hc/fr/articles/6653041523741`,
  urssaf: {
    portal: 'http://autoentrepreneur.urssaf.fr/',
    microEntrepreneurs:
      'https://www.autoentrepreneur.urssaf.fr/portail/accueil/sinformer-sur-le-statut/lessentiel-du-statut.html',
    socialContributions: 'http://www.aquoiserventlescotisations.urssaf.fr/',
    sepaMandate: `${faqDomainUrl}/hc/fr/articles/5605100609821`,
    faq: '',
    tdaeStatusHelp: '',
    tdaePaymentAuthorization: '',
    tou: 'https://assets.website-files.com/62baffcf7b968d00045514af/633aedcc31a7e75717e02246_LCL%20-%20CGU%20Tierce%20De%CC%81claration%20Client%20final%20x%20URSSAF-2_VF%20(1).pdf',
  },
  faqWhoIsBlankFor: `${faqDomainUrl}/hc/fr/articles/5661620067741`,
  psdFAQ: `${faqDomainUrl}/hc/fr/articles/11544977621277`,
  legalLink: 'https://info.essentielpro.lcl.fr',
  supportEmail: 'support@essentielpro.lcl.fr',
  securityEmail: 'securite@essentielpro.lcl.fr',
  leadEmail: '',
  sepaTransfer: `${faqDomainUrl}/hc/fr/articles/5605100609821`,
  compareOffers:
    'https://www.lcl.fr/professionnel/compte-bancaire-professionnel/compte-essentiel-pro-en-ligne',
  touBankAccount:
    'https://assets.website-files.com/62baffcf7b968d00045514af/633c2d9892b68fb22a090176_LCL%20-%20Contrat%20cadre%20de%20services%20de%20paiement%20Okali%20x%20client%20final.Vdef%20(1).pdf',
  kycHelp: `${faqDomainUrl}/hc/fr/articles/6986829659933`,
  legalStatus: 'https://web.essentielpro.lcl.fr/',
  capitalDeposit:
    'https://www.lcl.fr/professionnel/compte-bancaire-professionnel/compte-essentiel-pro-en-ligne',
  discoverManagementToolsOffer:
    'https://www.lcl.fr/professionnel/compte-bancaire-professionnel/essentiel-pro-gestion',
  sponsorshipFAQ: '',
  priceBrochure:
    'https://assets.website-files.com/62baffcf7b968d00045514af/633b0abd5607b55e289d701d_Plaquette%20tarifaire%20LCL.pdf',
  changeNonDiffusibleCompany: 'https://statut-diffusion-sirene.insee.fr',
  sponsorshipInvitationLink: '',
  transparencyPolicy: '',
  requestDeviceResetFAQ:
    'https://aide.essentielpro.lcl.fr/kb/guide/fr/comment-est-securise-mon-compte-appareil-de-reference-sca-code-de-securite-etc-m83NhNoDhi/Steps/2864353',
  accountClosingFaq:
    'https://aide.essentielpro.lcl.fr/kb/guide/fr/comment-puis-je-cloturer-mon-compte-vpwZtGhI34/Steps/2864408',
}
