import { ExternalLinks } from '../type'

const faqDomainUrl = 'https://aide.propulsebyca.fr'

export const CA_EXTERNAL_LINKS_FR: ExternalLinks = {
  cguLink:
    'https://assets.website-files.com/62bd73fb692211e3172975e4/633aed4914b3c41ea124cd52_CR%20-%20CGU_Plateforme%20de%20gestion%20Okali%20x%20Client%20final_VF%20(1).pdf',
  bankingOrganizationPrivacyPolicyLink:
    'https://assets.website-files.com/62bd73fb692211e3172975e4/633c5a4dd5457ace07c4d08f_Politique%20de%20protection%20des%20donnees%20Okali%20x%20Propulse_VF-n.pdf',
  brandPrivacyPolicyLink: 'https://info.propulsebyca.fr/caisses-regionales',
  appStoreLink: 'https://apps.apple.com/us/app/propulse-by-ca/id1623586276',
  googlePlayLink:
    'https://play.google.com/store/apps/details?id=com.creditagricole.pro',
  faq: faqDomainUrl,
  mandateSepa: `${faqDomainUrl}/hc/fr/articles/6267593411101`,
  invoicingFaq: `${faqDomainUrl}/hc/fr/sections/5723303440029`,
  requestHelp: `${faqDomainUrl}/hc/fr/requests/new`,
  rcProInfo: '',
  decadeGuaranteeInfo: '',
  multiriskInfo: '',
  legalPlaceCompany: 'https://heyflow.id/propulse-creation-corpo',
  helpNonDiffusible: `${faqDomainUrl}/hc/fr/articles/6203856149533`,
  helpNonEligible: `${faqDomainUrl}/hc/fr/articles/6203503940509`,
  helpBeneficialOwners: `${faqDomainUrl}/hc/fr/articles/6653098086557`,
  urssaf: {
    portal: 'http://autoentrepreneur.urssaf.fr/',
    microEntrepreneurs:
      'https://www.autoentrepreneur.urssaf.fr/portail/accueil/sinformer-sur-le-statut/lessentiel-du-statut.html',
    socialContributions: 'http://www.aquoiserventlescotisations.urssaf.fr/',
    sepaMandate: `${faqDomainUrl}/hc/fr/articles/6267593411101`,
    faq: '',
    tdaeStatusHelp: '',
    tdaePaymentAuthorization: '',
    tou: 'https://assets.website-files.com/62bd73fb692211e3172975e4/633aed6e43278997c780d425_CR%20-%20CGU%20Tierce%20De%CC%81claration%20Client%20final%20x%20URSSAF_VF%20(1).pdf',
  },
  faqWhoIsBlankFor: `${faqDomainUrl}/hc/fr/articles/6203572338333`,
  psdFAQ: `${faqDomainUrl}/hc/fr/articles/11549310165405`,
  legalLink: 'https://info.propulsebyca.fr',
  supportEmail: 'support@app.propulsebyca.fr',
  securityEmail: 'securite@app.propulsebyca.fr',
  leadEmail: '',
  sepaTransfer: `${faqDomainUrl}/hc/fr/articles/6267593411101`,
  compareOffers: 'https://propulsebyca.fr/tarifs',
  touBankAccount:
    'https://assets.website-files.com/62bd73fb692211e3172975e4/633aed5f1d4d477c75f4d99d_CR%20-%20Contrat%20cadre%20de%20services%20de%20paiement%20Okali%20x%20client%20final_VF%20(1).pdf',
  kycHelp: `${faqDomainUrl}/hc/fr/articles/6987070688157`,
  legalStatus: 'https://propulsebyca.fr/statut-juridique',
  capitalDeposit: 'https://propulsebyca.fr/depot-capital-social',
  discoverManagementToolsOffer:
    'https://www.credit-agricole.fr/professionnel/acces-cr.html',
  sponsorshipFAQ: '',
  priceBrochure:
    'https://assets.website-files.com/62bd73fb692211e3172975e4/6335c3f93bd65598eb4a584c_GrilletarifairePropulse.pdf',
  changeNonDiffusibleCompany: 'https://statut-diffusion-sirene.insee.fr',
  sponsorshipInvitationLink: '',
  transparencyPolicy: '',
  requestDeviceResetFAQ:
    'https://aide.propulsebyca.fr/kb/guide/fr/comment-est-securise-mon-compte-appareil-de-reference-sca-code-de-securite-etc-HaYIRHCWot/Steps/2863779',
  accountClosingFaq:
    'https://aide.propulsebyca.fr/kb/guide/fr/comment-puis-je-cloturer-mon-compte-SJf3TOPGvs/Steps/2863776',
}
